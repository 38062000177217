import Rails from '@rails/ujs';
import Swal from 'sweetalert2';

const nativeConfirm = Rails.confirm;
let __SkipConfirmation = false;

// Change rails default confirm handling.
// using https://sweetalert2.github.io/
//
Rails.confirm = function (message, element) {
  // JavaScript is single threaded. We can temporarily change this variable
  // in order to skip out of the confirmation logic.
  //
  // When this function returns true, the event (such as a click event) that
  // sourced it is not prevented from executing whatever it was supposed to
  // trigger, such as a form submission, or following a link.
  if (__SkipConfirmation) {
    return true;
  }
  // Here is the logic to determine if a custom dialog should be shown. In
  // this case, we'd expect [data-confirm-dialog="id"] to be present, but
  // other techniques, such as dynamically building a dialog from the
  // [data-confirm] content would also work.
  //
  // const dialogId = element.getAttribute('data-confirm-dialog');
  // if (!dialogId) {
  //   console.log("handling rails native dialog")

  //   return nativeConfirm(message, element);
  // }
  // This function should be executed when the dialog's positive action is
  // clicked. All it does is re-click the element that was originally
  // triggering this confirmation.
  //
  // Clicking that element will, as expected, re-call Rails.confirm (unless
  // we'd remove [data-confirm] temporarily, which is the alternative solution
  // to this), but because __SkipConfirmation is set, it will bail out early.
  function onConfirm() {
    __SkipConfirmation = true;
    element.click();
    __SkipConfirmation = false;
  }

  // The dialog should, on confirm, call onConfirm()
  // This ensures that the original event that caused this confirmation is
  // swallowed and the action is NOT executed.
  function showDialog(element, onConfirm) {
    const target = document.querySelector("#dialogs > *:last-child") || document.querySelector("body")
    const options = JSON.parse(element.getAttribute('data-sweet-alert') || '{}')
    var message = element.getAttribute('data-confirm')
    const title = element.getAttribute('data-confirm-title') || window.I18n.confirmation_dialog.title
    const cancelText = element.getAttribute('data-confirm-cancel') || window.I18n.confirmation_dialog.buttons.cancel
    const confirmText = element.getAttribute('data-confirm-confirm') || window.I18n.confirmation_dialog.buttons.confirm

    if (title == message) {
      message = ''
    }

    Swal.fire({
      target: target,
      customClass: {
        container: 'nobi-confirmation',
        confirmButton: 'nobi-confirm-button tw-px-7 tw-py-2.5 tw-m-[6px] tw-bg-primary-dark tw-border-0 focus:tw-outline-primary-dark tw-rounded-full tw-cursor-pointer tw-text-white tw-font-bold',
        cancelButton: 'tw-px-7 tw-py-2.5 tw-m-[6px] tw-bg-white tw-border tw-border-solid tw-border-primary-dark focus:tw-outline-primary-dark tw-cursor-pointer tw-rounded-full tw-text-primary-dark tw-font-bold'
      },
      animation: false,
      title: title,
      text: message,
      icon: 'warning',
      showCancelButton: true,
      heightAuto: false,
      cancelButtonText: cancelText,
      confirmButtonText: confirmText,
      confirmButtonColor: 'rgb(51, 112, 121)',
      buttonsStyling: false,
      reverseButtons: false,
      ...options
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm()
      }
    })

  }

  showDialog(element, onConfirm);

  return false;
};

